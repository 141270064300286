// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbar-container {
  position: fixed;
  top: 0;
  width: 1450px;
  height: 60px;
  background-color: rgba(235, 235, 235, 0.95);
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10; }
  @media screen and (max-width: 1450px) {
    .topbar-container {
      width: 95vw; } }

.topbar-btns {
  width: 480px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .topbar-btns > button {
    padding: 10px 20px;
    background-color: transparent;
    border: 0;
    border-radius: 10px; }
    .topbar-btns > button:hover {
      cursor: pointer;
      background-color: rgba(204, 204, 204, 0.3); }
    .topbar-btns > button:active {
      background-color: #CCCCCC; }
`, "",{"version":3,"sources":["webpack://./src/components/Professional/TopBar/TopBar.scss"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,MAAM;EACN,aAAa;EAEb,YAAY;EACZ,2CAA2C;EAC3C,4BAA4B;EAE5B,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW,EAAA;EAEX;IAdJ;MAeQ,WAAW,EAAA,EAGlB;;AAED;EACI,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB,EAAA;EALvB;IAQQ,kBAAkB;IAClB,6BAA6B;IAC7B,SAAS;IACT,mBAAmB,EAAA;IAX3B;MAcY,eAAe;MACf,0CAAsC,EAAA;IAflD;MAmBY,yBAAyB,EAAA","sourcesContent":[".topbar-container {\n    position: fixed;\n    top: 0;\n    width: 1450px;\n    // height: fit-content;\n    height: 60px;\n    background-color: rgba(235, 235, 235, 0.95);\n    border-radius: 0 0 10px 10px;\n\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    z-index: 10;\n\n    @media screen and (max-width: 1450px) {\n        width: 95vw;\n        // padding: 0;\n    }\n}\n\n.topbar-btns {\n    width: 480px;\n    height: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    &>button {\n        padding: 10px 20px;\n        background-color: transparent;\n        border: 0;\n        border-radius: 10px;\n\n        &:hover {\n            cursor: pointer;\n            background-color: rgba($color: #CCCCCC, $alpha: .3);\n        }\n\n        &:active {\n            background-color: #CCCCCC;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
