// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filler-gradient {
  position: absolute;
  top: 0;
  left: 0;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #0405aa;
  background: linear-gradient(to left bottom, #9696ff, #b4ffb4); }

.middle-block {
  width: 1450px;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 50px;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center; }
  .middle-block * {
    font-family: Arial, Helvetica, sans-serif; }
  @media screen and (max-width: 1450px) {
    .middle-block {
      width: 95vw; } }
`, "",{"version":3,"sources":["webpack://./src/components/Professional/MainPage.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB;EAClB,MAAM;EACN,OAAO;EAGP,wBAAmB;EAAnB,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,yBAAyB;EAEzB,6DAQ2B,EAAA;;AAG/B;EACI,aAAa;EAEb,wBAAmB;EAAnB,mBAAmB;EAEnB,iBAAiB;EACjB,0CAAsC;EACtC,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,mBAAmB,EAAA;EAVvB;IAaQ,yCAAyC,EAAA;EAG7C;IAhBJ;MAiBQ,WAAW,EAAA,EAElB","sourcesContent":[".filler-gradient {\n    position: absolute;\n    top: 0;\n    left: 0;\n\n    // height: 100%;\n    height: fit-content;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    background-color: #0405aa;\n\n    background:\n        // linear-gradient(30deg, rgb(0, 100, 255), rgb(0, 255, 180), rgb(0, 100, 255));\n        // linear-gradient(to right top,\n        //     rgb(0, 0, 136) 33%,\n        //     #FFD700,\n        //     rgb(0, 0, 136) 66%);\n        linear-gradient(to left bottom,\n            rgb(150, 150, 255),\n            rgb(180, 255, 180))\n}\n\n.middle-block {\n    width: 1450px;\n    // height: 100vh;\n    height: fit-content;\n    // height: 5000px;\n    padding-top: 50px;\n    background-color: rgba($color: #FFFFFF, $alpha: .9);\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n\n    & * {\n        font-family: Arial, Helvetica, sans-serif;\n    }\n\n    @media screen and (max-width: 1450px) {\n        width: 95vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
